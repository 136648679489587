window.onscroll = function() {myScroll()};
function myScroll() {
   var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
   var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
   var scrolled = (winScroll / height) * 100;
   document.getElementById("Bar").style.width = scrolled + "%";

   if(winScroll > 150) {
      document.getElementById("Hfixed").style.top =  '0px';
      document.getElementById("Hfixed").style.opacity =  '1';
   } else {
      document.getElementById("Hfixed").style.top =  '-100px';
      document.getElementById("Hfixed").style.opacity =  '0';
   }
}

function owlInitialize() {
   if ($(window).width() < 768) {
      $('.block-article__article-line').each(function(){
         $(this).children('.block-article__name').attr({'data-toggle':'modal','data-target':'#ModalZayavka'});
      });

      $('.photo-order__carousel').addClass("owl-carousel owl-theme");
      $('.photo-order__carousel').removeClass('row');
      $('.photo-order__carousel .photo-order__item').removeClass('col-4');
      $('.photo-order__carousel').owlCarousel({
         loop:true,
         nav:false,
         dots: false,
         //mouseDrag:false,
         responsive:{
            0:{
               items:1
            },
            768:{
               items:2
            },
            992:{
               items:3
            },
            1200:{
               items:2
            }
         }
      });
   }else{
      $('.photo-order__carousel').owlCarousel('destroy');
      $('.photo-order__carousel').addClass('row');
      $('.photo-order__carousel .photo-order__item').addClass('col-4');
      $('.photo-order__carousel').removeClass("owl-carousel owl-theme");
   }
}

var idCaptcha1, idCaptcha2, idCaptcha3;
var onloadReCaptchaInvisible = function() {
   idCaptcha1 = grecaptcha.render('recaptcha1', {
      "sitekey":"6Lc02AkaAAAAAPUegMBZpMd4Zar-L2y1mFUzlhnb",
      "callback": "onSubmitReCaptcha",
      "size":"invisible"
   });
   idCaptcha2 = grecaptcha.render('recaptcha2', {
      "sitekey":"6Lc02AkaAAAAAPUegMBZpMd4Zar-L2y1mFUzlhnb",
      "callback": "onSubmitReCaptchaTender",
      "size":"invisible"
   });
   idCaptcha3 = grecaptcha.render('recaptcha3', {
      "sitekey":"6Lc02AkaAAAAAPUegMBZpMd4Zar-L2y1mFUzlhnb",
      "callback": "onSubmitReCaptchaZayavka",
      "size":"invisible"
   });
};
function onSubmitReCaptcha(){
   $('.callback_form').prepend('<input type="hidden" name="ajax" value="Y">');
   var formData = new FormData($('form.callback_form')[0]);

   $.ajax({
      type: 'POST',
      url: $('.callback_form').attr('action'),
      data: formData,
      processData: false,
      contentType: false,
      success: function(data) {
         $('.form-zayavka input').val('');
         $('.form-zayavka textarea').val('');
         $('.form-zayavka__input-file').text('Загрузить файл');
         $('.form-zayavka input').removeClass('form-zayavka__correctly');
         $('.form-zayavka input').addClass('form-zayavka__empty');
         $('.zayavka-suc').addClass('show');
         $('body').addClass('modal-open');
         return false;

      },
      error:  function(xhr, str){
         alert('Возникла ошибка: ' + xhr.responseCode);
      }
   });
}

function onSubmitReCaptchaTender(){

   $('.tender_form').prepend('<input type="hidden" name="ajax" value="Y">');
   var formData = $('.tender_form').serialize();

   $.ajax({
      type: 'POST',
      url: $('.tender_form').attr('action'),
      data: formData,
      success: function(data) {
         $('.form-tender .form-modal__header').text('форма отправлена успешно');
         $('.form-tender .form-modal__modal-body .container form').remove();
         $('.form-tender .form-modal__manager-text').remove();
         $('.form-tender .form-modal__modal-header').css('justify-content','center');
         $('.form-tender .form-modal__modal-body .container').append('<div class="row justify-content-center"><div class="btn-new font-bebas-35 fon-red p-24-116 mb-30 border text-center" data-dismiss="modal">спасибо за заявку</div></div>');
         return false;

      },
      error:  function(xhr, str){
         alert('Возникла ошибка: ' + xhr.responseCode);
      }
   });
}

function onSubmitReCaptchaZayavka(){

   $('.zayavka_form').prepend('<input type="hidden" name="ajax" value="Y">');
   var formData = new FormData($('form.zayavka_form')[0]);

   $.ajax({
      type: 'POST',
      url: $('.zayavka_form').attr('action'),
      data: formData,
      processData: false,
      contentType: false,
      success: function(data) {
         $('.form-modal-zayavka .form-modal__header').text('форма отправлена успешно');
         $('.form-modal-zayavka .form-modal__modal-body .container form').remove();
         $('.form-modal-zayavka .form-modal__manager-text').remove();
         $('.form-modal-zayavka .form-modal__modal-header').css('justify-content','center');
         $('.form-modal-zayavka .form-modal__modal-body .container').append('<div class="row justify-content-center"><div class="btn-new font-bebas-35 fon-red p-24-116 mb-30 border text-center" data-dismiss="modal">спасибо за заявку</div></div>');
         return false;

      },
      error:  function(xhr, str){
         alert('Возникла ошибка: ' + xhr.responseCode);
      }
   });
}

$(window).resize(function() {
   owlInitialize();
});

$(document).ready(function(){
   owlInitialize();
   $('.form-close').click(function(){
      $('.zayavka-suc').removeClass('show');
      $('body').removeClass('modal-open');
   });
   $('.form-modal__zayavka-close').click(function(){
      $('.zayavka-suc').removeClass('show');
      $('body').removeClass('modal-open');
   });
   //отправка формы поиска
   $('.block-search__mobile-submit').click(function(){
      $("form.form-search").submit();
   });
   $("input[name=PHONE]").inputmask("+7 (999)-999-99-99");

   //обработка форм оставить заявку
   $('.form-zayavka__input-submit').click(function(){
      var flag = true;

      if($(".form-zayavka input[name=NAME]").val().length < 3){
         $(".form-zayavka input[name=NAME]").addClass('form-zayavka__error');
         $(".form-zayavka input[name=NAME]").removeClass('form-zayavka__empty');
         $(".form-zayavka input[name=NAME]").focus();
         flag = false;
      }
      else{
         $(".form-zayavka input[name=NAME]").removeClass('form-zayavka__error');
         $(".form-zayavka input[name=NAME]").addClass('form-zayavka__correctly');
      }

      if(/\+\d{1} \(\d{3}\)-\d{3}-\d{2}-\d{2}/g.test($(".form-zayavka input[name=PHONE]").val())){
         $(".form-zayavka input[name=PHONE]").removeClass('form-zayavka__error');
         $(".form-zayavka input[name=PHONE]").addClass('form-zayavka__correctly');
      } else {
         $(".form-zayavka input[name=PHONE]").addClass('form-zayavka__error');
         $(".form-zayavka input[name=PHONE]").removeClass('form-zayavka__empty');
         $(".form-zayavka input[name=PHONE]").focus()
         flag = false;
      }

      if(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})/g.test($(".form-zayavka input[name=EMAIL]").val())){
         $(".form-zayavka input[name=EMAIL]").removeClass('form-zayavka__error');
         $(".form-zayavka input[name=EMAIL]").addClass('form-zayavka__correctly');
      } else {
         $(".form-zayavka input[name=EMAIL]").addClass('form-zayavka__error');
         $(".form-zayavka input[name=EMAIL]").removeClass('form-zayavka__empty');
         $(".form-zayavka input[name=EMAIL]").focus();
         flag = false;
      }

      if(flag){
         grecaptcha.execute(idCaptcha1);
      }
   });
   //обработка форм позвонить, узнать цену
   $('.form-modal-zayavka__input-submit').click(function(){
      var flag = true;

      if($(".form-modal-zayavka input[name=NAME]").val().length < 3){
         $(".form-modal-zayavka input[name=NAME]").next().removeClass('form-modal__empty');
         $(".form-modal-zayavka input[name=NAME]").next().addClass('form-modal__error');
         $(".form-modal-zayavka input[name=NAME]").focus();
         flag = false;
      }
      else{
         $(".form-modal-zayavka input[name=NAME]").next().addClass('form-modal__correctly');
         $(".form-modal-zayavka input[name=NAME]").next().removeClass('form-modal__error');
      }

      if(/\+\d{1} \(\d{3}\)-\d{3}-\d{2}-\d{2}/g.test($(".form-modal-zayavka input[name=PHONE]").val())){
         $(".form-modal-zayavka input[name=PHONE]").next().addClass('form-modal__correctly');
         $(".form-modal-zayavka input[name=PHONE]").next().removeClass('form-modal__error');
      } else {
         $(".form-modal-zayavka input[name=PHONE]").next().removeClass('form-modal__correctly');
         $(".form-modal-zayavka input[name=PHONE]").next().addClass('form-modal__error');
         $(".form-modal-zayavka input[name=PHONE]").focus()
         flag = false;
      }

      if(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})/g.test($(".form-modal-zayavka input[name=EMAIL]").val())){
         $(".form-modal-zayavka input[name=EMAIL]").next().addClass('form-modal__correctly');
         $(".form-modal-zayavka input[name=EMAIL]").next().removeClass('form-modal__error');
      } else {
         $(".form-modal-zayavka input[name=EMAIL]").next().removeClass('form-modal__correctly');
         $(".form-modal-zayavka input[name=EMAIL]").next().addClass('form-modal__error');
         $(".form-modal-zayavka input[name=EMAIL]").focus()
         flag = false;
      }

      if(flag){
         grecaptcha.execute(idCaptcha3);
      }
   });

   //обработка форм
   $('.form-tender__input-submit').click(function(){
      var flag = true;

      if($(".form-tender input[name=NAME]").val().length < 3){
         $(".form-tender input[name=NAME]").next().removeClass('form-modal__empty');
         $(".form-tender input[name=NAME]").next().addClass('form-modal__error');
         $(".form-tender input[name=NAME]").focus();
         flag = false;
      }
      else{
         $(".form-tender input[name=NAME]").next().addClass('form-modal__correctly');
         $(".form-tender input[name=NAME]").next().removeClass('form-modal__error');
      }

      if(/\+\d{1} \(\d{3}\)-\d{3}-\d{2}-\d{2}/g.test($(".form-tender input[name=PHONE]").val())){
         $(".form-tender input[name=PHONE]").next().addClass('form-modal__correctly');
         $(".form-tender input[name=PHONE]").next().removeClass('form-modal__error');
      } else {
         $(".form-tender input[name=PHONE]").next().removeClass('form-modal__correctly');
         $(".form-tender input[name=PHONE]").next().addClass('form-modal__error');
         $(".form-tender input[name=PHONE]").focus()
         flag = false;
      }

      if(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})/g.test($(".form-tender input[name=EMAIL]").val())){
         $(".form-tender input[name=EMAIL]").next().addClass('form-modal__correctly');
         $(".form-tender input[name=EMAIL]").next().removeClass('form-modal__error');
      } else {
         $(".form-tender input[name=EMAIL]").next().removeClass('form-modal__correctly');
         $(".form-tender input[name=EMAIL]").next().addClass('form-modal__error');
         $(".form-tender input[name=EMAIL]").focus()
         flag = false;
      }


      if(flag){
         grecaptcha.execute(idCaptcha2);
      }
   });
   if ($(window).width() < 768){
      $('.calc-dostavka').click(function(){
         $('html, body').animate({
            scrollTop: $(".open-calculator").offset().top
         }, 1000);

         $('.open-calculator__button').addClass('active');
         $('.open-calculator__button').children('.open-calculator__arrow-up').css('transform','rotate(180deg)');
         $('.calculator').css('display','block');
      });
   } else {
      $('.calc-dostavka').click(function(){
         $('html, body').animate({
            scrollTop: $(".cdek").offset().top
         }, 1000);
      });
   }

   //Расчёт заново калькулятор
   $('.calculator__restart').click(function(){
      $(".calculator__form input").val('');
      $('.calculator__result-price').text('');
      $('.calculator__result').css('display','none');
      $('.calculator__submit').css({'background':'#b3312a','pointer-events':'visible'});
      $('.calculator__check-toggle').removeClass('active');
      $('.calculator__icon-check').css({'transform':'translateX(17px)','background':'url(/local/layouts/dist/img/check-inactive.png) no-repeat'});
      $('.calculator__check input').attr('value','N');
   });

   //Обработка калькулятора cdek
   $('.calculator__form').delegate('.calculator__submit','click',function(){

      var flag = true;

      if($("input[name=FROM]").val()==""){

         $("input[name=FROM]").css("border-color", "red");
         $("input[name=FROM]").focus();
         flag = false;
      }
      else{
         $("input[name=FROM]").css("border-color", "#cecece");
      }

      if($("input[name=TO]").val()==""){

         $("input[name=TO]").css("border-color", "red");
         $("input[name=TO]").focus();
         flag = false;
      }
      else{
         $("input[name=TO]").css("border-color", "#cecece");
      }

      if($("input[name=HEIGHT]").val()==""){
         $("input[name=HEIGHT]").css("border-color", "red");
         $("input[name=HEIGHT]").focus();
         flag = false;
      }
      else{
         jQuery("input[name=HEIGHT]").css("border-color", "#cecece");
      }

      if($("input[name=LENGTH]").val()==""){
         $("input[name=LENGTH]").css("border-color", "red");
         $("input[name=LENGTH]").focus();
         flag = false;
      }
      else{
         jQuery("input[name=LENGTH]").css("border-color", "#cecece");
      }

      if($("input[name=WIDTH]").val()==""){

         $("input[name=WIDTH]").css("border-color", "red");
         $("input[name=WIDTH]").focus();
         flag = false;
      }
      else{
         jQuery("input[name=WIDTH]").css("border-color", "#cecece");
      }

      if($("input[name=WEIGHT]").val() >= 30 || $("input[name=WEIGHT]").val() == ""){

         $("input[name=WEIGHT]").css("border-color", "red");

         $('.calculator__block-weight').find('span').text('до 30 кг');
         $("input[name=WEIGHT]").focus();
         flag = false;
      }
      else{
         $("input[name=WEIGHT]").css("border-color", "#cecece");
         $('.calculator__block-weight').find('span').text('');
      }

      if(flag){

         $(this).html(
             `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...`
         );

         var fromDoor = $("input[name=tarif1]").attr('value');
         var toDoor = $("input[name=tarif2]").attr('value');
         var tarif;

         if(fromDoor == 'N' && toDoor == 'N'){
            tarif = 10;
         } else if (fromDoor == 'N' && toDoor == 'Y'){
            tarif = 11;
         } else if(fromDoor == 'Y' && toDoor == 'N') {
            tarif = 12;
         } else if(fromDoor == 'Y' && toDoor == 'Y'){
            tarif = 1;
         }

         $.ajax({
            type:'POST',
            url:'/ajax/cdek.php',
            dataType: "json",
            data:
                {
                   'weight': $("input[name=WEIGHT]").val(),
                   'width':  $("input[name=WIDTH]").val(),
                   'length': $("input[name=LENGTH]").val(),
                   'height': $("input[name=HEIGHT]").val(),
                   'from':   $("input[name=FROM]").val(),
                   'to':     $("input[name=TO]").val(),
                   'contract': 2,
                   'pay_to': 0,
                   'tariffs':  tarif,
                   'insurance': $("input[name=INSURANCE]").val(),

                },
            success:function(jsondata)
            {
               $(jsondata[tarif]['error']).each(function(index,value){

                  if(value.code == 8){
                     $('.calculator__submit').text('рассчитать сроки и стоимость');
                     $("input[name=TO]").css('border','1px solid red');
                     $("input[name=TO]").focus();
                     return false;
                  }

                  if(value.code == 7) {
                     $('.calculator__submit').text('рассчитать сроки и стоимость');
                     $("input[name=FROM]").css('border','1px solid red');
                     $("input[name=FROM]").focus();
                     return false;
                  }

                  if(value.code == 3) {
                     $('.calculator__submit').text('рассчитать сроки и стоимость');
                     $("input[name=HEIGHT]").css('border','1px solid red');
                     $("input[name=LENGTH]").css('border','1px solid red');
                     $("input[name=WIDTH]").css('border','1px solid red');
                     $("input[name=HEIGHT]").focus();
                     return false;
                  }
               });
               $(jsondata[tarif]["result"]).each(function(index,value){

                  $('.calculator__submit').text('рассчитать сроки и стоимость');
                  $('.calculator__result').css('display','block');
                  $('.calculator__submit').css({'background':'#C4C4C4','pointer-events':'none'});
                  $('.calculator__result-price').text(value.price +' P');
               });
            }
         });
      }
   });

   //Открытие калькулятора на мобилки
   $('.open-calculator__button').click(function(){
      if ($(this).hasClass('active')){
         $(this).removeClass('active');
         $(this).children('.open-calculator__arrow-up').css('transform','rotate(0deg)');
         $('.calculator').css('display','none');
      } else {
         $(this).addClass('active');
         $(this).children('.open-calculator__arrow-up').css('transform','rotate(180deg)');
         $('.calculator').css('display','block');
      }

   });

   //Карусель Фотографии поставок
   $('.photo-order__carousel-next').click(function(e){
      e.preventDefault();
      e.stopPropagation();
      $('.photo-order__carousel').owlCarousel('next');
   });
   $('.photo-order__carousel-prev').click(function(e){
      e.preventDefault();
      e.stopPropagation();
      $('.photo-order__carousel').owlCarousel('prev');
   });
   $('.photo-order__carousel').on('changed.owl.carousel', function(event) {

      if(event.item.index > 0) {
         $('.photo-order__carousel-prev').css('display','block');
      } else {
         $('.photo-order__carousel-prev').css('display','none');
      }
   });

   //Переключатели калькулятора
   $('.calculator__check-toggle--ot').click(function(){
      if($(this).hasClass('active')){
         $(this).removeClass('active');
         $(this).children('.calculator__icon-check').css({'transform':'translateX(17px)','background':'url(/local/layouts/dist/img/check-inactive.png) no-repeat'});
         $(this).closest('.calculator__check').find('input[name=tarif1]').attr('value','N');
      } else {
         $(this).addClass('active');
         $(this).children('.calculator__icon-check').css({'transform':'translateX(0px)','background':'url(/local/layouts/dist/img/check-active.png) no-repeat'});
         $(this).closest('.calculator__check').find('input[name=tarif1]').attr('value','Y');
      }
   });
   $('.calculator__check-toggle--do').click(function(){
      if($(this).hasClass('active')){
         $(this).removeClass('active');
         $(this).children('.calculator__icon-check').css({'transform':'translateX(17px)','background':'url(/local/layouts/dist/img/check-inactive.png) no-repeat'});
         $(this).closest('.calculator__check').find('input[name=tarif2]').attr('value','N');
      } else {
         $(this).addClass('active');
         $(this).children('.calculator__icon-check').css({'transform':'translateX(0px)','background':'url(/local/layouts/dist/img/check-active.png) no-repeat'});
         $(this).closest('.calculator__check').find('input[name=tarif2]').attr('value','Y');
      }
   });

   //Карусель Отзывы
   $('.block-reviews__carousel-next').click(function(e){
      e.preventDefault();
      e.stopPropagation();
      $('.block-reviews__carousel').owlCarousel('next');
   });
   $('.block-reviews__carousel-prev').click(function(e){
      e.preventDefault();
      e.stopPropagation();
      $('.block-reviews__carousel').owlCarousel('prev');
   });
   $('.block-reviews__carousel').on('changed.owl.carousel', function(event) {

      if(event.item.index > 0) {
         $('.block-reviews__carousel-prev').css('display','block');
      } else {
         $('.block-reviews__carousel-prev').css('display','none');
      }
   });
   $('.block-reviews__carousel').owlCarousel({
      loop:false,
      margin:30,
      nav:false,
      dots: false,
      mouseDrag:false,
      responsive:{
         0:{
            loop:true,
            items:1,
         },
         768:{
            loop:true,
            items:2
         },
         992:{
            loop:true,
            items:3
         },
         1200:{
            items:4
         }
      }
   });

   //Карусель Аналоги
   $('.buy-analogi__carousel-next').click(function(e){
      e.preventDefault();
      e.stopPropagation();
      $('.buy-analogi__carousel').owlCarousel('next');
   });
   $('.buy-analogi__carousel-prev').click(function(e){
      e.preventDefault();
      e.stopPropagation();
      $('.buy-analogi__carousel').owlCarousel('prev');
   });
   $('.buy-analogi__carousel').on('changed.owl.carousel', function(event) {

      if(event.item.index > 0) {
         $('.buy-analogi__carousel-prev').css('display','block');
      } else {
         $('.buy-analogi__carousel-prev').css('display','none');
      }
   });
   $('.buy-analogi__carousel').owlCarousel({
      loop:true,
      margin:10,
      nav:false,
      dots: false,
      mouseDrag:false,
      responsive:{
         0:{
            items:1
         },
         420:{
            items:2
         },
         768:{
            items:3
         },
         992:{
            items:4
         },
         1200:{
            items:5
         }
      }
   });

   //Табы
   $('.block-cooperation__block-tabs').click(function(){
      $('.block-cooperation__block-tabs').removeClass('current');
      $(this).addClass('current');
      $('.context-tab').removeClass('visible');
      var tab = $(this).attr('data-tab');
      $(".context-tab[data-tab="+tab+"]").addClass('visible');
   });

   //Вверх
   $('.mobile-roll__block-roll').click(function(){
      $('body,html').animate({scrollTop: 0}, 400);
   });

   //Открытие алфавита для выбора другой буквы бренда
   $('.mobile-roll__text').click(function(){
      $('body,html').animate({scrollTop: 0}, 400);
      $('.filter-mobile__list-alphabet').addClass('active');
   });

   //Открытие для выбора другого буквы бренда
   $('.filter-mobile__another-letter').click(function(){
      $('.filter-mobile__list-alphabet').addClass('active');
      $('.filter-mobile__list-alphabet').css('width','100%');
   });

   //Очистка Выбранного бренда
   $('.filter-mobile').delegate('.filter-mobile__close .filter-mobile__arrow-up','click',function(event){
      $('.breadcrumbs').css('display', 'block');
      $('.header-page').css('display', 'block');
      $('.block-search').css('display', 'block');
      $('.block-manufactur-h1').css('display', 'block');
      $('.filter-mobile__block-another').css('display','none');
      $('.filter-mobile__button .filter-mobile__letter-brand').remove();
      $('.filter-mobile__button').removeClass('filter-mobile__close');
      $('.filter-mobile__item').removeClass('filter-mobile__item--active');
      $('.block-brand .block-brand__line').css('display','flex');
      $('.block-brand').css('margin-top', '0px');
      $('.filter-mobile__list-alphabet').removeClass('active');
      $('.filter-mobile__arrow-up').removeClass('filter-mobile__arrow-close');
      $('.mobile-roll').css('display','none');
      $('.filter-mobile').removeClass('p-0');
   });
   $('.filter-mobile').delegate('.filter-mobile__close','click',function(event){
      event.stopImmediatePropagation();
   });

   //Закрытие списка брендов
   $('.filter-mobile__roll-up').click(function(){
      $('.filter-mobile__list-alphabet').removeClass('active');
      $('.filter-mobile__button').find('.filter-mobile__arrow-up').removeClass('active');
   });

   //Открытие списка брендов
   $('.filter-mobile').delegate('.filter-mobile__button','click',function(){
      if($('.filter-mobile__list-alphabet').hasClass('active')){
         $('.filter-mobile__list-alphabet').removeClass('active');
         $(this).find('.filter-mobile__arrow-up').removeClass('active');
      } else {
         $('.filter-mobile__list-alphabet').width($('.filter-mobile .container').width());
         $('.filter-mobile__list-alphabet').addClass('active');
         $(this).find('.filter-mobile__arrow-up').addClass('active');
      }
   });

   //Вывод выбранного бренда
   $('.filter-mobile__list-alphabet').delegate('.filter-mobile__item','click',function(){
      if($(this).hasClass('filter-mobile__item--empty')){

      } else {
         if($(this).is('.filter-mobile__item--active')){
            $(this).removeClass('filter-mobile__item--active');
            $('.block-brand .block-brand__line.'+$(this).attr('data-letter')).css('display','none');
            $('.filter-mobile__block-another').css('display','none');

            if($('.filter-mobile__item--active').length < 1){
               $('.block-brand .block-brand__line').css('display','flex');
            }
         } else {
            $('.filter-mobile__list-alphabet .filter-mobile__item').removeClass('filter-mobile__item--active');
            $(this).addClass('filter-mobile__item--active');
            $('.filter-mobile__button').find('.filter-mobile__arrow-up').removeClass('active');
            $('.block-brand .block-brand__line').css('display','none');
            $('.block-brand .block-brand__line.'+$(this).attr('data-letter')).css('display','flex');
            $('.filter-mobile').addClass('p-0');
            $('.filter-mobile__button .filter-mobile__letter-brand').remove();
            $('.filter-mobile__button').prepend('<span class="filter-mobile__letter-brand">'+$(this).text()+'</span>');
            $('.filter-mobile__list-alphabet').removeClass('active');
            $('.breadcrumbs').css('display', 'none');
            $('.header-page').css('display', 'none');
            $('.block-search').css('display', 'none');
            $('.block-manufactur-h1').css('display', 'none');
            $('.filter-mobile__button').addClass('filter-mobile__close');
            $('.filter-mobile__block-another').css('display','block');
            $('.block-brand').css({'margin-top':'60px','display':'block'});
            $('.filter-mobile__arrow-up').addClass('filter-mobile__arrow-close');
            $('.mobile-roll').css('display','block');
            $('.filter-mobile__list-alphabet').css('width','100%');
            $('body,html').animate({scrollTop: 0}, 400);
         }
      }
   });

   //Открытие загрузки файлов
   $('.form-zayavka__input-file').click(function(){
      $('#files').click();
      $('#files').change(function() {
         var filename = $('#files').val();
         if (filename.substring(3,11) == 'fakepath') {
            filename = filename.substring(12);
         }
         $('.form-zayavka__input-file').html(filename);
      });
   });

   $('.form-modal-zayavka__text-list-buy').click(function(){
      $('#files2').click();
      $('#files2').change(function() {
         var filename = $('#files2').val();
         if (filename.substring(3,11) == 'fakepath') {
            filename = filename.substring(12);
         }
         $('.form-modal-zayavka__text-list-buy').html(filename);
      });
   });

   //Мобильное меню
   $('.mobileheader__burger').click(function(){
      if($(this).is('.active')){
         $(this).closest('.mobileheader').find('.mobileheader__menu').addClass('mobileheader__menu--close');
         $(this).removeClass('active');
         $(this).closest('.mobileheader').find('.mobileheader__menu').removeClass('active');
         $(this).children('.mobileheader__burger-line').eq(0).css({'transform' : 'none', 'position':'relative'});
         $(this).children('.mobileheader__burger-line').eq(1).css('display','block');
         $(this).children('.mobileheader__burger-line').eq(2).css({'transform' : 'none', 'position':'relative'});
         $(this).css('padding-top','0px');
         $("body").css("overflow-y", "auto");
      } else {
         $(this).closest('.mobileheader').find('.mobileheader__menu').removeClass('mobileheader__menu--close');
         $(this).addClass('active');
         $(this).closest('.mobileheader').find('.mobileheader__menu').addClass('active');
         $(this).children('.mobileheader__burger-line').eq(0).css({'transform' : 'rotate(45deg)', 'position':'absolute'});
         $(this).children('.mobileheader__burger-line').eq(1).css('display','none');
         $(this).children('.mobileheader__burger-line').eq(2).css({'transform' : 'rotate(-45deg)', 'position':'absolute'});
         $(this).css('padding-top','7px');
         $("body").css("overflow-y", "hidden");
      }
   });

   //Вывод выбранный брендов
   $('.block-filter__list-alphabet').delegate('.block-filter__item','click',function(){
      if($(this).hasClass('block-filter__item--empty')){

      } else {
         if($(this).is('.block-filter__item--active')){
            $(this).removeClass('block-filter__item--active');
            $(this).addClass('block-filter__item--inactive');
            $('.block-filter__item--inactive').each(function(index){
               $('.block-brand .block-brand__line.'+$(this).attr('data-letter')).css('display','none');
            })

            if($('.block-filter__item--active').length < 1){
               $('.block-brand .block-brand__line').css('display','flex');
            }
         } else {
            $(this).addClass('block-filter__item--active');
            $(this).removeClass('block-filter__item--inactive');
            $('.block-brand .block-brand__line').css('display','none');
            $('.block-filter__item--active').each(function(){
               $('.block-brand .block-brand__line.'+$(this).attr('data-letter')).css('display','flex');
            });
         }
      }
   });

   $('.block-filter__item--empty').hover(
       function() {
          $(this).removeClass('.block-filter__item--empty').addClass('block-filter__item--empty2');
       }, function() {
          $(this).removeClass('block-filter__item--empty2');
       }
   );

   //Очистка списка брендов
   $('.block-filter__filter-reset').click(function(){
      $('.block-filter__item').removeClass('block-filter__item--active');
      $('.block-filter__item').removeClass('block-filter__item--inactive');
      $('.block-brand__line').css('display','flex');
   });
});